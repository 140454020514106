<template>
    <div data-app>

      <v-row>
        <!-- <v-col offset-sm="2" offset-md="1" offset-lg="1" offset-xl="2">
        </v-col> -->
        <v-col class="text-center" cols="12" >
            <h2 class="titleformfac">Aquí puedes consultar el contrato de adhesión y acceder al formulario de facturación.</h2>
            <br/>
            <img class="imageContract" src="/contrato.webp" alt="plaza las américas">
            <h3 class="textSubtituloGray" style="margin-top:41px"><br>
              Accede desde aqui
            </h3>
        
        </v-col>
       <!--  <v-col  offset-sm="2" offset-md="2" offset-lg="2" offset-xl="2">
        </v-col> -->



      </v-row>

       <v-row>
       
          <v-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
        
        <v-btn
          class="btnServ"
          height="48px"
          rounded
          @click="goContract()"

        >Contrato</v-btn>
            
        <v-btn
          class="btnServ"
          height="48px"
          rounded
          @click="goForm()"
        >
          Formulario de facturación
        </v-btn>
        <br/>
        <h3 class="textSubtituloGray">
          Recomendamos usar una red WiFi estable.
        </h3>

          </v-col>
        
        </v-row>
  
  
      <v-row>
        <v-col  offset="12">
        </v-col>
      </v-row>
      <v-row>
        <v-col  offset="12">
        </v-col>
      </v-row>
      <v-row>
        <v-col  offset="12">
        </v-col>
      </v-row>
      <v-row>
        <v-col  offset="12">
        </v-col>
      </v-row>
      <v-row>
        <v-col  offset="12">
        </v-col>
      </v-row>
  
  </div>
  </template>
  
  <script>
  // @ is an alias to /src
  /* import HelloWorld from '@/components/HelloWorld.vue' */
  
  export default {
  props: {
  },
    methods: {
      goForm(){
          window.open('http://ela.citelis.mx/', '_blank', 'noreferrer');
        
      },
      goContract(){
        window.open('/contrato-ela.pdf', '_blank', 'noreferrer');
      }
    },
    data: () => ({
    
    }),
    name: 'MainPage',
    components: {
      /* HelloWorld */
    }
  }
  </script>
  
  <style scoped>
  
    /*  ESTILOS DE BOTONES  */
    .btnServ{
      background: #FF5100 0% 0% !important; 
      color:white !important;
      text-transform: initial;
      font-family: "Metropolis_Bolt";
      font-size: 18px;
      width: 310px;
      margin-top: 20px;
      margin-left: 15px;
      /*box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;
      opacity: 1;
      color:white !important;
      padding: 0px !important;
      width: 140px;
      height: 33px;
      transition: 0.6s; */
    }
    
    .btnServ:hover{
      background: #f5903d 0% 0% !important; 
      color:white !important;
    /*  border-radius: 2px !important;
      opacity: 1 !important; 
      color:white !important;
      padding: 0px !important; */
    }
    
    .titleformfac{
    font-family: "Metropolis_Bolt";
    font-size: 22px;
    margin-top: 135px;
    color: #1F2933;
    margin-bottom: auto;
    }
  
    .textSubtituloGray{
      font-family: "Metropolis_regular";
      font-size: 14px;
      color: #3F3F3F;
      margin-top: 25px;
    }
  

    .imageContract{
      width: 205px;
      height: 220px;
    }
/* CAMBIOS DE ESTILOS SELECT  
    .v-select__selection {
      text-align: left !important;
      justify-content: left !important;
  }
  
  .v-text-field--outlined >>> fieldset {
    border-color:#0051DF;
  }
  
  
  .selecAgencia{
    margin-top: 127px;
  } */
  
  .textTitulo{
      font-family: "Metropolis_semiBolt";
      font-size: 22px;
      margin-top: 50px;
    }
    
  /* movil */
  @media only screen and (max-width: 768px) {

  .titleformfac{
      margin-top: 25px;
      margin-left: 35px;
      margin-right: 35px;
  } 



  }
  
  
  
  
  </style>
  