<template>
  
  <v-app>
    <v-app-bar :elevation="2" color="#FF5100">
      <a href="https://citelis.com.mx/life-center/espacio-las-americas">
        <img
        class="citeLogo"
        alt="las americas"
        src="/americas-logo-blanco.png"
      >
      
      </a>
      
    </v-app-bar>

    <v-main>
      <!-- <HelloWorld/> -->
      <MainPage/>

    </v-main>
    <citelis-footer position="relative"></citelis-footer>
  </v-app>
</template>

<script>
import MainPage from './components/MainPage.vue'
/* import { useMeta } from 'vue-meta' */
export default {
  name: 'App',
  metaInfo: {
      title: "test meta data with vue",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"hello world, this is an example of adding a description with vueMeta"
        }
      ]
  },
  components: {
    MainPage
  },

  data: () => ({
    //
  }),
  

};
</script>

<style >

html {
    overflow-x: hidden;
    width: 100%;
}

 body {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%
}

.textAvisoPriv{
  text-decoration: none;
  font-family: "Metropolis_regular";
  font-size: 14px;
  text-align: end;
  color: #000;
  margin-top: 5px;

}

.v-footer.sonbon {
  padding: 0px;
}

.colorTopBar{
  background-color: #E8E8E8;
}

.textDerechos{
  font-family: "Metropolis_regular";
  font-size: 10px;
  text-align: left;
  color: #3F3F3F;
}

@font-face {
  font-family: "Metropolis_Bolt";
  src: local("Metropolis_Bolt"),
  url(./assets/fonts/MetropolisFont/Metropolis-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis_semiBolt";
  src: local("Metropolis_semiBolt"),
  url(./assets/fonts/MetropolisFont/Metropolis-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis_regular";
  src: local("Metropolis_regular"),
  url(./assets/fonts/MetropolisFont/Metropolis-Regular.otf) format("truetype");
}

.footerColor{
  background-color: #E8E8E8 !important;
}

.bottomFooterColor {
  background-color:  #F5F5F5 !important;
}

  /* logo para dispositivos mayores a 768px */
  .citeLogo{
    margin-left:130px ;
  }

  @media only screen and (max-width: 768px) {
    .citeLogo{
      margin-left:30px ;
    }

  }
</style>